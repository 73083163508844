<script setup lang="ts">
import { AButton } from '#components'
import defu from 'defu'
import emblaCarouselVue from 'embla-carousel-vue'
import type { EmblaCarouselType, EmblaOptionsType, EmblaPluginType } from 'embla-carousel'
import type { AutoplayType } from 'embla-carousel-autoplay'

interface CarouselDefaults { embla: EmblaOptionsType, nav: typeof props.navProps }

defineOptions({ inheritAttrs: false })

const props = withDefaults(defineProps<{
  options?: EmblaOptionsType
  plugins?: EmblaPluginType[]
  nav?: boolean
  navProps?: Pick<InstanceType<typeof AButton>['$props'], 'size' | 'material' | 'color'>
  useSlideCss?: boolean
  clip?: boolean
}>(), {
  useSlideCss: false,
  clip: true,
})

const defaults: CarouselDefaults = {
  embla: {
    loop: true,
    align: 'start',
    skipSnaps: true,
  },
  nav: {
    size: 'fluid',
    material: 'empty',
    color: 'blue',
  },
}

const [emblaRef, emblaApi] = emblaCarouselVue(defu(props.options, defaults.embla), props.plugins)

function stopAutoplay(carousel: EmblaCarouselType) {
  const autoplay: AutoplayType = carousel.plugins().autoplay
  autoplay?.stop()
}
</script>

<template>
  <div
    ref="emblaRef"
    :class="{ 'overflow-hidden': clip }"
  >
    <div
      v-bind="$attrs"
      class="flex"
      :class="{ '[&_>_*]:max-w-full [&_>_*]:min-w-0 [&_>_*]:shrink-0 [&_>_*]:grow-0 [&_>_*]:basis-full [&_>_*]:mx-2 [&_>_*]:2xl:basis-1/4 [&_>_*]:lg:basis-1/3 [&_>_*]:md:basis-1/2': !useSlideCss }"
    >
      <slot />
    </div>
  </div>
  <slot name="nav">
    <div
      v-if="nav && emblaApi"
      class="mt-4 text-center space-x-8"
      @click="stopAutoplay(emblaApi)"
    >
      <AButton
        label="Previous"
        icon="i-carbon-chevron-left scale-150"
        icon-only
        v-bind="defu(props.navProps, defaults.nav)"
        @click="emblaApi.scrollPrev()"
      />
      <AButton
        label="Next"
        icon="i-carbon-chevron-right scale-150"
        icon-only
        v-bind="defu(props.navProps, defaults.nav)"
        @click="emblaApi.scrollNext()"
      />
    </div>
  </slot>
</template>
